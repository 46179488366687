import { useAsyncStrapiApi, useStrapiApi } from "~/uses/useMyFetch";
import { categoriesQuery } from "~/nuxt_modules/blog/utils/query.utils";

export const useBlogStore = defineStore("blogStore", () => {
  const categories = ref(null);

  const fetchCategories = async () => {
    return useAsyncStrapiApi(`/blog/categories?${categoriesQuery}`).then(
      ({ data }) => {
        categories.value = data.value.data;
      },
    );
  };

  return { categories, fetchCategories };
});

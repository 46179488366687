<template>
  <div class="post-preview">
    <PostLink class="post-preview__image" :post="postPreview">
      <img
        :src="normalizeStrapiUrl(postPreview.cover.formats.thumbnail.url)"
        :alt="postPreview.cover.caption ?? 'blog preview image'"
        loading="lazy"
      />
    </PostLink>
    <div class="post-preview__content">
      <div class="post-preview__content-header">
        <PostPreviewTags
          v-if="0 !== postPreview.tags"
          :tags="postPreview.tags"
        />
        <div class="post-preview__views">
          <img
            src="~/assets/icons/view-icon.svg"
            alt="views count"
            class="post-preview__views-image"
          />
          <span class="post-preview__views-count">
            {{ postPreview.views }}
          </span>
        </div>
      </div>
      <PostLink class="post-preview__title" :post="postPreview">
        {{ postPreview.title }}
      </PostLink>
      <div class="post-preview__content-footer">
        <div class="post-preview__author">
          <img
            :src="normalizeStrapiUrl(postPreview.author.photo.url)"
            :alt="postPreview.author.name"
            class="post-preview__author-photo"
          />
          <span class="post-preview__author-name">
            {{ postPreview.author.name }}
          </span>
        </div>
        <div class="post-preview__content-footer-wrapper">
          <time
            :datetime="new Date(postPreview.createdAt).toISOString()"
            class="post-preview__date"
          >
            {{ getDate(postPreview.createdAt) }}
          </time>
          <div class="post-preview__views mobile">
            <img
              src="~/assets/icons/view-icon.svg"
              alt="views count"
              class="post-preview__views-image"
            />
            <span class="post-preview__views-count">
              {{ postPreview.views }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { normalizeStrapiUrl } from "~/utils/normalize";
import PostPreviewTags from "~/nuxt_modules/blog/ui/components/PostPreviewTags.vue";
import PostLink from "~/nuxt_modules/blog/ui/components/PostLink.vue";

const props = defineProps({
  postPreview: { type: Object, required: true },
});

const getDate = (date) =>
  useState("blog card date" + date, () =>
    new Intl.DateTimeFormat("uk-UA", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    }).format(Date.parse(date)),
  );
</script>

<style lang="scss" scoped>
.post-preview {
  width: 100%;
  min-width: 348px;
  max-width: 348px;

  @include flex-container(column, flex-start);
  gap: 16px;

  background-color: white;
  border-radius: 16px;

  @include mobile {
    min-width: 164px;
    max-width: 164px;
  }

  &__image {
    & > img {
      width: 100%;
      aspect-ratio: 16 / 9;
      object-fit: cover;
      border-radius: 16px;
    }
  }

  &__content {
    height: 100%;

    @include flex-container(column, flex-start);

    padding: 0 16px 16px;
    gap: 16px;

    @include mobile {
      gap: 8px;
      padding: 0 8px 8px;
    }
  }

  &__content-header {
    @include flex-container(row, space-between, center);
    gap: 8px;
  }

  &__views {
    @include flex-container(row, flex-start, center);
    gap: 4px;

    margin-left: auto;

    @include mobile {
      display: none;
    }

    &.mobile {
      display: none;

      @include mobile {
        display: flex;
      }
    }
  }

  &__views-image {
    @include fixedHW(24px);
  }

  &__views-count {
    @include font(12, 18, 500);
    color: var(--color-sky-darker);
  }

  &__title {
    display: -webkit-box;

    @include font(18, 24, 600);
    text-wrap: balance;

    transition: color 0.2s;

    &:hover {
      color: var(--color-primary-base);
    }

    @include mobile {
      @include font(12, 18, 500);
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  &__content-footer {
    @include flex-container(row, space-between, center);
    flex-wrap: wrap;
    gap: 8px;

    margin-top: auto;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__content-footer-wrapper {
    @include mobile {
      width: 100%;

      @include flex-container(row, space-between, center);
      gap: 4px;
    }
  }

  &__author {
    @include flex-container(row, flex-start, center);
    gap: 8px;
  }

  &__author-photo {
    @include fixedHW(30px);

    border-radius: 50%;
    object-fit: contain;
  }

  &__author-name {
    @include font(12, 18, 500);
  }

  &__date {
    @include font(14, 20);
    color: var(--color-sky-darker);
  }
}
</style>

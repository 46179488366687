<template>
  <div class="article">
    <div class="article__w">
      <article class="article__article">
        <h1 class="article__title">
          {{ post.title }}
        </h1>
        <time
          :datetime="new Date(post.createdAt).toISOString()"
          class="article__data"
        >
          {{ _T("@blog articles published") }}:
          {{ getDate(post.createdAt) }}
        </time>
        <div class="article__banner">
          <img
            :src="normalizeStrapiUrl(post.cover.url)"
            :alt="post.cover.caption"
            class="article__banner-img"
            loading="lazy"
          />
        </div>
        <div class="article__body">
          <div class="ui-rich-text" v-html="post.content" />
        </div>
      </article>
    </div>
    <div
      v-if="null !== products && products.length > 0"
      class="article__slider"
    >
      <ProductCarousel
        :products="products"
        :title="_T('@Blog product slider header')"
      />
    </div>
    <slot name="similarSlider" />
  </div>
</template>

<script setup>
import ProductCarousel from "~/modules/shared/sliders/ProductCarousel.vue";

const props = defineProps({
  post: { type: Object, required: true },
  currentRoute: { type: Object, required: true },
});

useSeoMeta({
  title: props.post.metaTitle,
  description: props.post.metaDescription,
});

const products = ref(null);
const getDate = (date) => {
  return useState("article date" + date, () =>
    new Intl.DateTimeFormat("uk-UA", {}).format(Date.parse(date)),
  );
};

const getJsonLd = (post, currentRoute) => {
  return {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": getUrl(getLocalizedRouteName(currentRoute.routeName), {
        ...currentRoute.params,
      }),
    },
    headline: post.title,
    image: normalizeStrapiUrl(post.cover.url),
    author: {
      "@type": "Person",
      name: post.author.name,
      image: normalizeStrapiUrl(post.author.photo.url),
    },
    publisher: {
      "@type": "Organization",
      name: _T("@Trademark"),
      logo: {
        "@type": "ImageObject",
        url: useRuntimeConfig().public.appUrl + "/img/logo-short-orange.svg",
      },
    },
    datePublished: new Date(post.createdAt).toISOString(),
    articleBody: post.content.replace(/<[^>]*>/g, ""),
  };
};

useHead({
  script: [
    {
      type: "application/ld+json",
      children: getJsonLd(props.post, props.currentRoute),
    },
  ],
});
</script>

<style lang="scss">
.article {
  @include flex-container(column, flex-start, center);
  gap: 24px;

  padding: 24px 16px;

  @include mobile {
    padding: 16px;
  }

  &__w {
    @extend %width-main;

    @include flex-container(column, center, center);

    background-color: white;
    border-radius: 8px;

    padding: 24px 16px;

    @include mobile {
      gap: 16px;

      padding: 16px 8px;
    }
  }

  &__article {
    max-width: 1200px;

    @include flex-container(column, flex-start, baseline);
    gap: 24px;
  }

  &__title {
    @include font(24, 30, 700);
    text-align: center;
  }

  &__banner {
    height: 355px;
    width: 100%;

    @include mobile {
      height: 228px;
    }
  }

  &__banner-img {
    width: 100%;
    height: 100%;

    object-fit: cover;

    border-radius: 8px;
  }

  &__data {
    @include font(16, 20, 500);
    color: #747474;

    @include mobile {
      @include font(14, 18, 500);
    }
  }

  &__body {
    @include flex-container(row, flex-start, flex-start);
  }

  &__slider {
    @extend %width-main;
    margin: auto;
  }

  &__more {
    @include font(22, 26, 600);
    text-transform: uppercase;
  }
}
</style>

<template>
  <footer class="footer">
    <section class="footer__cont">
      <div class="content">
        <Logo class="content__logo-img-w" />
        <FooterList :title="_T('@Products')" :list="categories">
          <MyLink class="content__catalog-link" name="catalog">
            {{ _T("@Go to catalog") }}
          </MyLink>
        </FooterList>
        <FooterList :title="_T('@Information')" :list="infoList" />
        <FooterContacts />
        <div class="content__copyrights">
          <Logo class="content__logo-img-w" />
          <div class="content__copyrights-unit">
            <p class="content__copyrights-caption">
              {{ _T("@Years of experience in provision of goods") }}
            </p>
            <p class="content__caption">
              &#169; {{ _T("@All rights reserved") }}
            </p>
          </div>
          <div class="content__copyrights-unit">
            <div class="content__copyrights-img-w">
              <img
                class="content__copyrights-img"
                src="~~/assets/icons/footerMasterCardIcon.svg"
                alt="MasterCard"
                loading="lazy"
              />
            </div>
            <div class="content__copyrights-img-w">
              <img
                class="content__copyrights-img"
                src="~~/assets/icons/footerVisaIcon.svg"
                alt="Visa"
                loading="lazy"
              />
            </div>
          </div>
          <p v-if="_T('@footer entity') !== 'empty'" class="content__caption">
            {{ _T("@footer entity") }}
          </p>
        </div>
      </div>
      <div class="subscription__block">
        <div class="subscription__block-mailing">
          <div class="subscription__block-mailing-info">
            <h4 class="subscription__block-title">
              {{ _T("@Newsletter") }}
            </h4>
            <p class="subscription__block-text">
              {{ _T("@Subscribe to newsletter") }}
            </p>
          </div>
          <form class="subscription__block-mailing-form">
            <input
              v-model="email"
              class="subscription__block-mailing-input"
              type="email"
              placeholder="E-mail"
            />
            <button
              class="subscription__block-mailing-btn"
              @click.prevent="onSubscribe"
            >
              {{ _T("@Subscribe") }}
            </button>
          </form>
        </div>
        <div class="subscription__block-social">
          <div class="subscription__block-social-w">
            <h4 class="subscription__block-title">
              {{ _T("@Join footer") }}
            </h4>
            <ul class="subscription__block-social-list">
              <li
                v-for="social in socials"
                class="subscription__block-social-list-item"
              >
                <a
                  :href="social.link"
                  class="subscription__block-social-list-item-link"
                  target="_blank"
                >
                  <img
                    :src="social.iconWhite"
                    :alt="social.alt"
                    loading="lazy"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div class="content__copyrights-unit">
            <div class="content__copyrights-img-w">
              <img
                class="content__copyrights-img"
                src="@/assets/icons/footerMasterCardIcon.svg"
                alt="MasterCard"
                loading="lazy"
              />
            </div>
            <div class="content__copyrights-img-w">
              <img
                class="content__copyrights-img"
                src="@/assets/icons/footerVisaIcon.svg"
                alt="Visa"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import FooterList from "./FooterList.vue";
import { useCategoriesStore } from "~~/store/categoriesStore";
import { emailValidate, requiredValidate } from "~/utils/validators";
import socials from "~/config/social.config";
import Logo from "~~/modules/header/components/UI/Logo.vue";
import { useAPI } from "~/uses/useMyFetch";
import FooterContacts from "~/modules/footer/components/FooterContacts.vue";

const categoriesStore = useCategoriesStore();
const { getTopLevelCategories: categories } = storeToRefs(categoriesStore);

const infoList = [
  { id: 2, title: "@Contacts", routeName: "contacts" },
  { id: 3, title: "@Blog", routeName: "blog" },
  { id: 4, title: "@Delivery and payment", routeName: "delivery" },
  { id: 5, title: "@Warranty and Service", routeName: "warranty" },
  { id: 6, title: "@Privacy Policy", routeName: "privacy-policy" },
  { id: 7, title: "@Public offer agreement", routeName: "public-offer" },
  { id: 8, title: "@Cooperation", routeName: "cooperation" },
  { id: 11, title: "@Serial Number Check", routeName: "lookup-device" },
  { id: 12, title: "@Capacity Calculator", routeName: "calculator" },
  {
    id: 13,
    title: "@Household appliance power calculator",
    routeName: "powerCalculator",
  },
  { id: 14, title: "@Our objects", routeName: "installation-work" },
];

const email = ref("");

const rules = computed(() => ({
  required: requiredValidate(),
  email: emailValidate(),
}));

const v = useVuelidate(rules, email);

const onSubscribe = async () => {
  if (await v.value.$validate()) {
    useAPI("/marketing/event", {
      method: "POST",
      body: {
        event: "subscription",
        email: email.value,
        isConfirmed: false,
        params: {
          origin: "footer",
        },
      },
    }).then(() => {
      email.value = "";
      v.value.$reset();
    });
  }
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;

  @include flex-container(column, stretch, center);

  background-color: var(--color-grey-900);

  @extend %padding-wrp;

  &__cont {
    @extend %width-main;

    @include flex-container(column, stretch, center);
    gap: 48px;

    padding: 64px 0 40px;

    @include mobile {
      gap: 32px;

      padding: 48px 0;
    }
  }
}

.content {
  width: 100%;

  @include flex-container;
  gap: 24px 16px;

  & > .content__logo-img-w {
    display: none;

    @include mobile {
      display: block;
      margin-bottom: 16px;
    }
  }

  @include bigMobile {
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  @include mobile {
    flex-direction: column;
  }

  &__catalog-link {
    width: 100%;
    max-width: 200px;

    color: var(--color-primary-base);

    transition: color 0.2s ease;

    &:hover {
      color: var(--color-primary-dark);
    }

    @include mobile {
      display: none;
    }
  }

  &__title {
    position: relative;
    @include font(20, 30, 600);
    text-transform: uppercase;
    color: var(--color-primary-base);
  }

  &__block-list-link {
    width: 100%;
    max-width: 200px;

    @include font;
    letter-spacing: 0.02em;
    color: white;

    transition: color 0.2s ease-in-out;

    @include mobile {
      max-width: none;
    }

    &:hover {
      color: var(--color-primary-dark);
    }
  }

  &__caption {
    @include font(12, 16);
    letter-spacing: 0.02em;
    color: var(--color-sky-darker);
  }

  &__copyrights {
    width: 100%;
    max-width: 200px;

    @include flex-container(column, stretch, center);
    gap: 16px;

    @include mobile {
      display: none;
    }
  }

  &__logo-img-w {
    font-size: 0;

    @include mobile {
      display: none;
    }
  }

  &__copyrights-unit {
    @include flex-container(column, stretch, center);
    gap: 8px;

    & .content__caption {
      width: 100%;
      max-width: 120px;
      text-align: center;
    }

    & + & {
      @include flex-container;
      gap: 0;
    }
  }

  &__copyrights-caption {
    @include font(12, 16);
    letter-spacing: 0.02em;
    text-align: center;
    color: white;
  }
}

.subscription {
  &__block {
    width: 100%;

    @include flex-container(row, space-between, center);
    gap: 16px;

    border-radius: 16px;
    background-color: var(--color-sky-darkest);

    padding: 16px 32px;

    @include mobile {
      @include flex-container(column, center, flex-start);

      background: none;

      padding: 0;
    }
  }

  &__block-mailing {
    width: 100%;

    @include flex-container(row, flex-start, center);
    gap: 32px;

    @include bigMobile {
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  &__block-mailing-info {
    width: 100%;
    max-width: 355px;

    @include flex-container(column, stretch);
    gap: 16px;

    @include mobile {
      max-width: none;
      gap: 8px;
    }

    & .subscription__block-title {
      @include mobile {
        color: var(--color-primary-base);
      }
    }
  }

  &__block-title {
    @include font(20, 30, 600);
    text-transform: uppercase;
    color: white;
  }

  &__block-text {
    @include font(12, 16);
    letter-spacing: 0.02em;
    color: white;

    @include mobile {
      @include font(12, 16);
      color: var(--color-sky-lighter);
    }
  }

  &__block-mailing-form {
    width: 100%;
    max-width: 484px;

    @include flex-container(row, center, center);
  }

  &__block-mailing-input {
    width: 100%;
    max-height: 40px;

    border-radius: 6px 0 0 6px;

    background-color: white;

    padding: 12px 8px;

    &::placeholder {
      @include font(12, 16);
      letter-spacing: 0.02em;
      color: var(--color-ink-light);
    }
  }

  &__block-mailing-btn {
    position: relative;
    width: 100%;
    max-width: 183px;

    @include flex-container(row, center, center);

    @include font(18, 24);
    letter-spacing: 0.02em;
    color: white;

    border-radius: 6px;
    background-color: var(--color-primary-base);

    margin-left: -8px;
    padding: 8px 0;

    transition: color 0.2s ease-in-out;

    &:hover {
      background-color: var(--color-primary-dark);
    }
  }

  &__block-social {
    @include mobile {
      width: 100%;
      @include flex-container(row, space-between, center);
      gap: 8px;
    }

    & .content__copyrights-unit {
      display: none;

      @include mobile {
        @include flex-container(column, center, center);
      }
    }
  }

  &__block-social-w {
    @include flex-container(column, stretch, center);
    gap: 16px;
  }

  &__block-social-list {
    @include flex-container(row, center, center);
    gap: 24px;
  }

  &__block-social-list-item-link {
    font-size: 0;
    display: inline-block;

    & svg path {
      transition: transform 0.2s ease-in-out;
    }

    &:hover svg {
      transform: scale(1.1);
    }
  }
}
</style>

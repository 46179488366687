<script setup>
import { useStrapiApi } from "~/uses/useMyFetch";
import qs from "qs";
import Subscription from "~/nuxt_modules/blog/ui/components/Subscription.vue";
import PopularTags from "~/nuxt_modules/blog/ui/pages/Blog/PopularTags.vue";
import TagPosts from "~/nuxt_modules/blog/ui/pages/Blog/TagPosts.vue";
import {
  getPostsQuery,
  tagsQuery,
} from "~/nuxt_modules/blog/utils/query.utils";
import { useBlogStore } from "~/nuxt_modules/blog/store/blogStore";
import { useLanguageStore } from "~/store/languageStore";

const query = qs.stringify({
  locale: "ru",
  fields: ["title", "slug", "views", "createdAt"],
  pagination: {
    page: 1,
    pageSize: 4,
  },
  populate: {
    author: {
      populate: ["photo"],
    },
    tags: true,
    cover: true,
  },
  // filters: {
  //   tags: {
  //     slug: "some",
  //   },
  // },
});

const blogStore = useBlogStore();
const { language } = useLanguageStore();

const { data: blogData } = await useAsyncData(
  "fetchBlogData",
  async () => {
    const [previews, tags] = await Promise.all([
      useStrapiApi(`/blog/posts?${getPostsQuery(language.name)}`),
      useStrapiApi(`/blog/tags?${tagsQuery}`),
      // new Promise((resolve) => resolve(blogStore.fetchCategories())),
      // blogStore.fetchCategories(),
    ]);

    return {
      previews: previews.data,
      tags: tags.data,
    };
  },
  { watch: language, immediate: true, deep: true },
);
</script>

<template>
  <div class="blog">
    <TagPosts :post-previews="blogData.previews" />
    <Subscription />
    <PopularTags :tags="blogData.tags" />
  </div>
</template>

<style scoped lang="scss">
.blog {
  @extend %width-main;

  @include flex-container(column, flex-start);
  gap: 16px;

  margin-inline: auto;
}
</style>

import qs from "qs";

export const categoriesQuery = qs.stringify({
  fields: ["name", "slug"],
});

export const tagsQuery = qs.stringify({
  fields: ["name", "slug"],
});

export const getPostsQuery = (
  locale,
  page = 1,
  filters = undefined,
  pageSize = 24,
) => {
  return qs.stringify({
    locale,
    fields: ["title", "slug", "views", "createdAt"],
    pagination: {
      page,
      pageSize,
    },
    populate: {
      author: {
        populate: ["photo"],
      },
      tags: true,
      cover: true,
    },
    filters: filters,
  });
};

export const postQuery = (locale) =>
  qs.stringify({
    locale,
    populate: {
      author: {
        populate: ["photo"],
      },
      tags: true,
      cover: true,
    },
  });
